import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import styles from './NavDrawer.module.scss'

const NavDrawer = ({ navDrawerOpen, toggleNavDrawer }) => {
  const dtcURL = process.env.GATSBY_DEV
    ? 'https://dutytocountry-v2.whatscookin.com/'
    : 'https://dutytocountry.org/'

  const links = useStaticQuery(graphql`
    query {
      allStrapiChapter(sort: { fields: chapter_number }) {
        nodes {
          chapter_slug
          title
          subtitle
          description
        }
      }
      allStrapiSection(sort: { fields: section_code }) {
        nodes {
          chapter {
            chapter_slug
          }
          section_slug
          section_title
          section_date
          section_desc
        }
      }
    }
  `)

  const navRef = useRef(null) // A wrapper so we can use querySelectorAll
  const detailsRef = useRef(null) // array of details elements

  // Get all the details elements and add them to the array
  useEffect(() => {
    detailsRef.current = navRef.current.querySelectorAll('details')
  }, [navRef])

  // Loop through the details elements and if they don't match the clicked one, remove the 'open' attribute. The clicked one maintains its default behavior
  const toggleOpen = e => {
    detailsRef.current.forEach(detail => {
      if (detail !== e.currentTarget) {
        detail.removeAttribute('open')
      }
    })
  }

  useEffect(() => {
    if (navDrawerOpen) {
      disablePageScroll(navRef.current) // continue to allow scrolling on navRef.current
    } else {
      enablePageScroll(navRef.current)
    }
  }, [navDrawerOpen])

  return (
    <nav className={`${styles.navContainer}`} ref={navRef}>
      <Link
        to={`/`}
        className={`h4 mt32`}
        activeClassName={`${styles.activeStaticLink}`}
        onClick={toggleNavDrawer}
      >
        {/* FIXME mobile margin is weird */}
        <h2 className={`h4 inlineBlock ${styles.staticLink}`}>
          <span className={`textColorFuzzy`}>Home:</span> Under One Flag
        </h2>
        {/* <div className={`h4 pl32 `}>
          <span className={`textColorFuzzy`}>Home:</span> Under One Flag
        </div> */}
      </Link>
      {/* <hr className={`mb32 backgroundSilver`} /> */}
      <div className={`${styles.columnsContainer}`}>
        <section className={`${styles.storyContainer}`}>
          {/* FIXME mobile margin is weird */}
          <h2 className={`h4 mb16`}>The Story</h2>
          <ul>
            {links.allStrapiChapter.nodes.map(
              ({ chapter_slug, title, subtitle, description }, index) => {
                return (
                  <li key={`nav-${chapter_slug}`}>
                    {/* note: eslint doesn't like the event handlers on details, but I don't think it's really a problem */}
                    {/* eslint-disable-next-line */}
                    <details
                      onClick={e => {
                        toggleOpen(e)
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          toggleOpen(e)
                        }
                      }}
                    >
                      <summary className={`h4 relPos ${styles.summary}`}>
                        <div className={`${styles.chapterText}`}>
                          <h3 className={`h5`}>
                            <span className={`textColorFuzzy`}>
                              {subtitle + ': '}
                            </span>
                            {title}
                          </h3>
                          <p className={`${styles.descriptionText}`}>
                            {description}
                          </p>
                        </div>
                      </summary>
                      <div className={`${styles.detailsContainer}`}>
                        <span className={`${styles.timelineCircle}`} />
                        {links.allStrapiSection.nodes.map(
                          ({
                            chapter,
                            section_slug,
                            section_title,
                            section_desc,
                            section_date,
                          }) => {
                            if (chapter.chapter_slug === chapter_slug) {
                              return (
                                <Link
                                  key={`nav-${section_slug}`}
                                  to={`/${chapter_slug}/${section_slug}/`}
                                  className={`${styles.chapterLink}`}
                                  activeClassName={`${styles.activeChapterLink}`}
                                  onClick={toggleNavDrawer}
                                >
                                  <p className={`h5 ${styles.dateText}`}>
                                    {section_date}
                                  </p>
                                  <p className={`h5 ${styles.linkTitle}`}>
                                    {section_title}
                                  </p>
                                  <p className={`${styles.descriptionText}`}>
                                    {section_desc}
                                  </p>
                                </Link>
                              )
                            } else return null
                          }
                        )}
                      </div>
                    </details>
                  </li>
                )
              }
            )}
          </ul>
        </section>
        {/* <hr className={`mt16 backgroundSilver`} /> */}

        <section className={`${styles.pagesContainer}  `}>
          <h2 className={`h4 mb16`}>Media</h2>
          <ul>
            <li className={`pt2 mb40`}>
              <Link
                to={`/animation-gallery/`}
                className={`h5 ${styles.staticLink}`}
                activeClassName={`${styles.activeStaticLink}`}
                onClick={toggleNavDrawer}
              >
                <h3 className={`h5`}>Animations</h3>
                <p className={`${styles.descriptionText}`}>
                  Experience the stories of veterans and activists brought to
                  life through short animations, ranging from firefights in the
                  jungle to protests in Congress.
                </p>
              </Link>
            </li>
            <li className={`mb40`}>
              <Link
                to={`/profile-cards/`}
                className={`h5 ${styles.staticLink}`}
                activeClassName={`${styles.activeStaticLink}`}
                onClick={toggleNavDrawer}
              >
                <h3 className={`h5`}>Profile Cards</h3>
                <p className={`${styles.descriptionText}`}>
                  Learn the roles played by key people in this story by browsing
                  their profile cards, featuring illustrated portraits and short
                  biographies.
                </p>
              </Link>
            </li>
            <li className={`mb40`}>
              <Link
                to={`/illustration-gallery/`}
                className={`h5 ${styles.staticLink}`}
                activeClassName={`${styles.activeStaticLink}`}
                onClick={toggleNavDrawer}
              >
                <h3 className={`h5`}>Illustrations</h3>
                <p className={`${styles.descriptionText}`}>
                  Witness significant moments across this history brought to
                  life through the richly detailed illustrations crafted by
                  artist Aria Villafranca.{' '}
                </p>
              </Link>
            </li>
          </ul>
        </section>
      </div>
      {/* <hr className={`backgroundSilver`} /> */}

      {/* <a
        href={`${${dtcURL}}`}
        className={`mt32 mb32 block ${styles.staticLink}`}
        // target="_blank"
        // rel="noopener"
      >
        <p className={`h5`}>
          <span className={`textColorFuzzy`}>
            Return to Duty To Country Education Project
          </span>
          
        </p>
      </a> */}
      <section>
        <h2 className={`h5 mb8`}>Duty to Country Education Project</h2>
        <ul className={`${styles.dtcLinksContainer}`}>
          <li className={`semibold ${styles.descriptionText}`}>
            <a href={`${dtcURL}`}>Duty to Country Home</a>
          </li>
          <li className={`semibold ${styles.descriptionText}`}>
            <a href={`${dtcURL}oral-histories/`}>Oral Histories</a>
          </li>
          <li className={`semibold ${styles.descriptionText}`}>
            <a href={`${dtcURL}for-educators/`}>For Educators</a>
          </li>
          <li className={`semibold ${styles.descriptionText}`}>
            <a href={`${dtcURL}get-involved/`}>Get Involved</a>
          </li>
          <li className={`semibold ${styles.descriptionText}`}>
            <a href={`${dtcURL}about/`}>About</a>
          </li>
        </ul>
      </section>

      <p className={`footer`}>
        Copyright {new Date().getFullYear()}. All Rights Reserved.
      </p>
    </nav>
  )
}

export default NavDrawer
