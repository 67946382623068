import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import logo from '../../images/underoneflag.png'
import ArrowIcon from '../icons/ArrowIcon'
import NavDrawer from './NavDrawer'
import styles from './TopNav.module.scss'

const TopNav = ({ children }) => {
  const navDrawerRef = useRef()
  const [navDrawerOpen, setNavDrawerOpen] = useState(false)

  const dtcURL = process.env.GATSBY_DEV
    ? 'https://dutytocountry-v2.whatscookin.com/'
    : 'https://dutytocountry.org/'

  const toggleNavDrawer = () => {
    setNavDrawerOpen(!navDrawerOpen)
  }

  useEffect(() => {
    if (navDrawerOpen) {
      // Update the aria-expanded attribute
      navDrawerRef.current.setAttribute('aria-expanded', true)
      // Show the nav drawer
      navDrawerRef.current.hidden = false
      document.body.style.overflowY = 'hidden'
    } else if (!navDrawerOpen) {
      // Hide the nav drawer
      navDrawerRef.current.hidden = true
      // Update the aria-expanded attribute
      navDrawerRef.current.setAttribute('aria-expanded', false)
      document.body.style.overflowY = 'unset'
    }
  }, [navDrawerRef, navDrawerOpen])

  return (
    <header
      className={`${styles.topNavContainer}`}
      aria-label="Overall Site Nav"
      data-scroll-lock-fill-gap // from the scroll-lock package
    >
      <div className={`${styles.dtcLinkWrapper}`}>
        <a href={`${dtcURL}`} className={`h5 ${styles.dtcLink}`}>
          <i className={`inlineIcon ${styles.dtcArrow}`}>
            <ArrowIcon />
          </i>{' '}
          Duty to Country
        </a>
      </div>
      <div className={`${styles.logoWrapper}`}>
        <Link
          to="/"
          aria-label="Under One Flag"
          className={`${styles.logo}`}
          style={{
            background: `url(${logo}) no-repeat left center / contain`,
          }}
          onClick={() => setNavDrawerOpen(false)}
        ></Link>
      </div>
      {children && <div className={`${styles.topNavChildren}`}>{children}</div>}
      <button
        className={`${styles.hamburgerBtn} ${navDrawerOpen && styles.closeBtn}`}
        type="button"
        onClick={toggleNavDrawer}
        aria-haspopup="true"
        aria-label={navDrawerOpen ? 'Close Menu' : 'Open Menu'}
      >
        {/* <svg viewBox="0 0 100 100" width="32" height="32">
          <rect id={styles.top} y="20" width="100" height="6"></rect>
          <rect id={styles.middle} y="47" width="100" height="6"></rect>
          <rect id={styles.bottom} y="74" width="100" height="6"></rect>
        </svg> */}
        <svg viewBox="0 0 32 32">
          <rect id={styles.top} y="5" width="32" height="2"></rect>
          <rect id={styles.middle} y="15" width="32" height="2"></rect>
          <rect id={styles.bottom} y="25" width="32" height="2"></rect>
        </svg>
      </button>
      <nav
        id="navDrawer"
        ref={navDrawerRef}
        aria-label="All Pages"
        // aria-expanded="false"
        hidden
      >
        {/* Hamburger Menu items are in NavDrawer.js */}
        <NavDrawer
          navDrawerOpen={navDrawerOpen}
          toggleNavDrawer={toggleNavDrawer}
        />
      </nav>
    </header>
  )
}

TopNav.propTypes = {
  children: PropTypes.node,
}

export default TopNav
