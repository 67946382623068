import React from 'react'

const ArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 51" role="img" title="Pointer Arrow">
      <polygon points="41.6 26.2 24.5 42.76 24.5 0.6 20.5 0.6 20.5 42.75 3.4 26.1 0.6 29 22.5 50.4 44.4 29.1 41.6 26.2" />
    </svg>
  )
}

export default ArrowIcon
